.section-1 {
  background-image: url("../../images/home-banner-1.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 1060px;
}

@media (max-width: 991px) {
  .section-1 {
    background-image: url("../../images/home-banner-mb-1.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    height: auto;
  }
}

.card-home-1 {
  width: 50rem;
  background-color: transparent;
  border: none;
}

.card-home-1 .card-body {
  padding: 1rem 2rem;
}

.card-home-1 .card-title {
  color: #f40;
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 97.5%;
  /* 46.8px */
}

.card-home-1 .card-subtitle {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-home-1 .card-text {
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.card-home-1 .btn-container {
  margin-top: 7rem;
}

@media (max-width: 991px) {
  .card-home-1 {
    width: 100%;
  }

  .card-home-1 .card-title {
    font-size: 28px;
  }

  .card-home-1 .card-subtitle {
    font-size: 22px;
  }

  .card-home-1 .card-text {
    font-size: 16px;
  }

  .card-home-1 .card-body {
    padding: 1rem 0rem;
  }

  .card-home-1 .btn-container {
    margin-top: 3rem;
  }
}

.section-2 {
  color: #212121;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 28.8px */
}

.section-2-title {
  position: absolute;
  top: 50px;
  left: 215px;

  color: #2c2c2c;
  text-align: right;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 97.5%;
  text-transform: uppercase;
}

.section-2-title span {
  color: #f40;
  text-align: right;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: 97.5%;
  /* 72.798px */
  text-transform: uppercase;
}

.section-2-divider {
  width: 5px;
  height: 315px;
  background: linear-gradient(
    90deg,
    rgba(255, 68, 0, 0.5) 0%,
    rgba(201, 54, 0, 0.2) 100%
  );
  margin: 0 15px;
  padding: 0;
}

@media (max-width: 1580px) {
  .section-2 {
    font-size: 20px;
  }

  .section-2-title {
    position: absolute;
    top: 45px;
    left: 50%;

    font-size: 26px;
  }
  .section-2-title span {
    font-size: 46px;
  }
}

@media (max-width: 1199px) {
  .section-2 {
    font-size: 16px;
  }

  .section-2-title {
    position: absolute;
    top: 45px;
    left: 55%;

    font-size: 22px;
  }
  .section-2-title span {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .section-2 {
    font-size: 16px;
  }

  .section-2 img {
    width: 100%;
    height: 180px;
  }

  .section-2-title {
    top: 140px;
    left: 10px;

    text-align: left;
    font-size: 26px;
  }

  .section-2-title span {
    font-size: 42px;
  }

  .section-2-divider {
    display: none;
  }
}

.section-3 {
  padding: 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;

  background-image: url("../../images/home-banner-2.png");
  background-blend-mode: lighten, normal;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 365px;
}

.section-3-title {
  text-align: center;
  color: #000;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.section-3-title span {
  color: #f40;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 6px;
}

@media (max-width: 991px) {
  .section-3 {
    padding: 2rem 0rem;
    height: auto;
  }

  .section-3-title {
    font-size: 24px;
  }

  .section-3-title span {
    font-size: 24px;
  }
}
