:root {
    --primary: #FF4400;
    --outline-background: #fff;
    --border-color: rgba(255, 153, 116, 0.40);
}

.btn {
    cursor: pointer;
}
.btn-link{
    text-decoration: none;
}

.btn-primary {
    display: inline-flex;
    padding: 7.069px 28.275px;
    justify-content: center;
    align-items: center;
    gap: 7.069px;

    color: #FFF;
    font-family: "DM Sans";
    font-size: 21.206px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 28.275px;
    border-top: 1.767px solid var(--border-color);
    border-right: 0.884px solid var(--border-color);
    border-bottom: 1.767px solid var(--border-color);
    border-left: 0.884px solid var(--border-color);
    background: var(--primary);
    box-shadow: 0px -1.767px 3.534px 0px rgba(255, 198, 178, 0.25), 0px 3.534px 6.185px 0px rgba(255, 198, 178, 0.25);
}

.btn-primary:hover,
.btn-primary:focus {
    color: #212121;
    background: #fff;
    border-top: 0px solid rgba(255, 153, 116, 0.40);
    border-right: 1px solid rgba(255, 153, 116, 0.40);
    border-bottom: 2px solid rgba(255, 153, 116, 0.40);
    border-left: 1px solid rgba(255, 153, 116, 0.40);
    transition: all 0.3 ease-out;
}

.btn-outline {
    display: flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    color: #212121;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    border-radius: 32px;
    border-top: 0px solid rgba(255, 153, 116, 0.40);
    border-right: 1px solid rgba(255, 153, 116, 0.40);
    border-bottom: 2px solid rgba(255, 153, 116, 0.40);
    border-left: 1px solid rgba(255, 153, 116, 0.40);
    background: #FFF;
    box-shadow: 0px -2px 4px 0px rgba(255, 198, 178, 0.25), 0px 4px 7px 0px rgba(255, 198, 178, 0.25);
    transition: all 0.3 ease-out;
}
.btn-outline:hover,
.btn-outline:focus {
    color: #fff;
    background: var(--primary);
    transition: all 0.3 ease-out;
}

@media (max-width: 991px) {
    .btn-primary {
        font-size: 16px;
    }
    .btn-outline {
        font-size: 16px;
    }
  }