.card-primary {
  display: flex;
  width: 358px;
  padding: 60px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;

  border-radius: 60px;
  background: #c93600;
}

.card-outline {
  display: inline-flex;
  padding: 36px 50px 6px 50px;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  border: 2px solid #f40;
  background: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(255, 68, 0, 0.1) 0%,
    rgba(155, 155, 155, 0) 100%
  );
  box-shadow: -5px -5px 250px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(21px);
}

.card-25 {
  width: 55vh;
  margin: 1rem;
}

.card-400 {
  width: 368px;
  height: 370px;
  margin: 1rem;
}

.card-860 {
  /*width: 862px;*/
  /*width: 730px;*/
  width: 686px;
  height: 520px;
  margin: 0rem;
}

.card-full {
  width: 100%;
  padding: 50px 50px 50px 50px;
  margin: 0rem;
}

.card-body {
  display: flex;
  flex-direction: column;
  /*justify-content: space-between;*/
}

.card-primary .card-title {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.card-primary .card-text {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: justify;
}

.card-title {
  color: #212121;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 28.8px */
}

.card-text {
  color: #212121;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 26.4px */
}

.card-link {
  color: #f40;
  font-family: "DM Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

.card-link:hover {
  color: #f40;
  text-decoration: underline;
}

@media (max-width: 1199px) {
  .card-outline {
    padding: 39px 25px 50.862px 25px;
  }

  .card-title {
    font-size: 20px;
  }

  .card-text {
    font-size: 18px;
  }

  .card-link {
    font-size: 20px;
  }

  .card-400 {
    width: 320px;
    height: 370px;
    margin: 0rem;
  }

  .card-860 {
    width: 545px;
    height: 520px;
    margin: 0rem;
  }
}

@media (max-width: 991px) {
  .card-400 {
    width: 100%;
    height: auto;
    margin: 0rem;
  }

  .card-860 {
    width: 100%;
    height: auto;
    margin: 0rem;
  }

  .card-primary .card-title {
    font-size: 18px;
  }
  
  .card-primary .card-text {
    font-size: 16px;
  }

  .card-title {
    font-size: 18px;
  }

  .card-text {
    font-size: 16px;
  }

  .card-link {
    font-size: 16px;
  }
}
