.navbar{
    background: #fff;
    border-bottom: solid 1px rgba(170, 168, 167, 0.50);
}

.nav-links {
    color: #212121;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration: none;
}
.nav-links:hover {
    color: #F40;
}