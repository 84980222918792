.contact-section-1 {
  background-image: url("../../images/contact-banner-1.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
}

.contact-section-1 h1 {
  color: #000;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3rem 2rem 2rem 2rem;
  margin: 0;
}
@media (max-width: 1199px) {
  .contact-section-1 h1 {
    font-size: 42px;
    padding: 3rem 0rem 2rem 0rem;
  }
}
@media (max-width: 991px) {
  .contact-section-1 {
    height: auto;
  }

  .contact-section-1 h1 {
    font-size: 36px;
  }
}

.contact-section-1 h2 {
    color: #212121;
    font-family: "DM Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 28.8px */
}

.contact-section-1 p {
    color: #212121;
    font-family: "DM Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.card-contact-2 {
    margin: 0rem;
    padding: 50px 50px 50px 50px;

    color: #212121;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 48px */

    position: relative;
    overflow: hidden;
    border-radius: 52.539px;
    border: 2.102px solid #F40;
    background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 68, 0, 0.10) 0%, rgba(155, 155, 155, 0.00) 100%);
    box-shadow: -5.254px -5.254px 262.695px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(22.06640625px);
}

textarea.form-control {
    height: 140px;
}

@media (max-width: 991px) {
  .contact-section-1 h2 {
    font-size: 18px;
}
}