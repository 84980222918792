.research-section-1 h1 {
  color: #000;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3rem 2rem 2rem 2rem;
  margin: 0;
}

@media (max-width: 1199px) {
  .research-section-1 h1 {
    font-size: 42px;
    padding: 3rem 0rem 2rem 0rem;
  }
}
@media (max-width: 991px) {
  .research-section-1 {
    background-image: url("../../images/research-banner-mb-1.png");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
    height: auto;
  }

  .research-section-1 h1 {
    font-size: 36px;
  }
}

.research-section-2 {
  background-image: url("../../images/research-banner-1.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;
}

.research-section-2 h2 {
  color: #f40;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

.research-section-2 h3 {
  color: #212121;
  font-family: "DM Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 38.4px */
}

.research-section-2 p {
  color: #212121;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.research-section-2 span {
  color: #212121;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}
@media (max-width: 991px) {
  .research-section-2 h2 {
    font-size: 22px;
    text-align: left;
  }

  .research-section-2 h3 {
    font-size: 18px;
    text-align: left;
  }

  .research-section-2 p {
    font-size: 16px;
  }

  .research-section-2 span {
    font-size: 16px;
  }
}
