.grey-border-bottom {
  width: 98%;
  height: 1px;
  background: rgba(170, 168, 167, 0.5);
}

.products-section-1 {
  background-image: url("../../images/products-banner-1.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  height: 700px;
}

.products-section-1 h1 {
  color: #000;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3rem 2rem 2rem 2rem;
  margin: 0;
}

@media (max-width: 1199px) {
  .products-section-1 h1 {
    font-size: 42px;
    padding: 3rem 0rem 2rem 0rem;
  }
}
@media (max-width: 991px) {
  .products-section-1 {
    height: auto;
  }
  .products-section-1 h1 {
    font-size: 36px;
  }
}

.products-section-2 {
  background-image: url("../../images/products-banner-2.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: contain;
}

.products-section-2 h2 {
  color: #000;
  font-family: "DM Sans";
  font-size: 23.564px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.products-section-2 h3 {
  color: #f40;
  text-align: center;
  font-family: "DM Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.products-section-2 p {
  color: #212121;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 19.2px */
}

.products-section-2 span {
  color: #f40;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

@media (max-width: 991px) {
  .products-section-2 {
    background-image: url("../../images/products-banner-mb-2.png");
    background-size: cover;
  }

  .products-section-2 h2 {
    font-size: 23.564px;
  }

  .products-section-2 p {
    font-size: 16px;
    text-align: center;
  }

  .products-section-2 span {
    font-size: 36px;
  }
}

.products-section-2 .mobile-card {
  background-image: url("../../images/products-img-mb-1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: cover;

  width: 354px;
  height: 80px;

  color: #fff;
  text-align: center;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
}

.orange-line-y {
  width: 2px;
  height: 73px;
  background: linear-gradient(
    90deg,
    rgba(255, 68, 0, 0.6) 0%,
    rgba(201, 54, 0, 0.24) 100%
  );
}
