.card-contact {
    height: 290px;
    margin: 0rem;
    padding: 50px 50px 6px 50px;

    color: #212121;
    font-family: "DM Sans";
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 48px */

    position: relative;
    overflow: hidden;
    border-radius: 52.539px;
    border: 2.102px solid #F40;
    background: radial-gradient(107.32% 141.42% at 0% 0%, rgba(255, 68, 0, 0.10) 0%, rgba(155, 155, 155, 0.00) 100%);
    box-shadow: -5.254px -5.254px 262.695px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(22.06640625px);
}

.form-control {
    display: inline-flex;
    height: 69px;
    padding: 21px 160px 19px 30px;
    align-items: center;
    flex-shrink: 0;

    border-radius: 20px;
    border: 2.102px solid rgba(255, 68, 0, 0.40);
    background: rgba(255, 255, 255, 0.50);
    box-shadow: -5.254px -5.254px 262.695px 0px rgba(255, 255, 255, 0.02) inset;
    backdrop-filter: blur(22.06640625px);
}

@media (max-width: 991px) {
    .card-contact {
        height: auto;
        font-size: 24px;
        padding: 30px 30px 30px 30px;
    }

    .form-control {
        height: 50px;
        padding: 15px 15px 15px 15px;
    }
  }