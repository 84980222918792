.services-section-1 {
    background-image: url('../../images/products-banner-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: contain;
    height: 700px;
}

.services-section-1 h1 {
    color: #000;
    font-family: "DM Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 3rem 2rem 2rem 2rem;
    margin: 0;
}

@media (max-width: 1199px) {
    .services-section-1 h1 {
      font-size: 42px;
      padding: 3rem 0rem 2rem 0rem;
    }
  }
  @media (max-width: 991px) {
    .services-section-1 {
      height: auto;
    }
  
    .services-section-1 h1 {
      font-size: 36px;
    }
  }

.services-section-2 {
    background-image: url('../../images/services-banner-1.png');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: auto;
}

.services-section-2 h2 {
    color: #F40;
    font-family: "DM Sans";
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
}

.services-section-2 p {
    color: #212121;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.services-section-2 span {
    color: #212121;
    font-family: "DM Sans";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 38.4px */
}

@media (max-width: 991px) {
  .services-section-2 h2 {
    font-size: 18px;
}

.services-section-2 p {
    font-size: 16px;
}

.services-section-2 span {
    font-size: 16px;
}
}