* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DM Sans", sans-serif;
}
body {
  overflow-x: hidden;
}

.section-margin {
  margin-bottom: 8rem;
}
@media (max-width: 991px) {
  .section-margin {
    margin-bottom: 4rem;
  }
}
