.image-card {
  position: relative;
  overflow: hidden;
  border-radius: 52.539px;
  border: 2.102px solid #f40;
  background: radial-gradient(
    107.32% 141.42% at 0% 0%,
    rgba(255, 68, 0, 0.1) 0%,
    rgba(155, 155, 155, 0) 100%
  );
  box-shadow: -5.254px -5.254px 262.695px 0px rgba(255, 255, 255, 0.02) inset;
  backdrop-filter: blur(22.06640625px);
}

.image-card img{
  height: -webkit-fill-available;
}

.image-card .text-container {
  position: absolute;
  top: 80%;
  left: 15px;
  width: 150px;
}

.image-card .title {
  color: #fff;
  font-family: Inter;
  font-size: 13.968px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.image-card .text {
  color: #fff;
  font-family: Inter;
  font-size: 13.968px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-190 {
  width: 190px;
  height: 520px;
  margin: 0rem;
}

.card-190x190 {
  width: 190px;
  /*height: 190px;*/
  margin: 1rem;
}

.card-450 {
  /*width: 450px;*/
  width: 350px;
  height: 520px;
  margin: 0rem;
}

@media (max-width: 1199px) {
  .card-190 {
    width: 170px;
  }

  .card-450 {
    margin: 0rem;
  }
}

@media (max-width: 991px) {
  .image-card.card-190 .text-container {
    top: 65%;
  }

  .card-190 {
    width: 190px;
    height: 230px;
    margin: 0rem;
  }

  .card-190x190 {
    width: 150px;
    /*height: 190px;*/
    margin: 0rem;
  }

  .card-450 {
    width: 100%;
    height: auto;
  }
}
