.about-section-1 {
  background-image: url("../../images/about-banner-1.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: contain;
  height: 700px;
}

.about-section-1 h1 {
  color: #000;
  font-family: "DM Sans";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 3rem 2rem 2rem 2rem;
  margin: 0;
}
@media (max-width: 1199px) {
  .about-section-1 h1 {
    font-size: 42px;
    padding: 3rem 0rem 2rem 0rem;
  }
}
@media (max-width: 991px) {
  .about-section-1 {
    height: auto;
  }

  .about-section-1 h1 {
    font-size: 36px;
  }
}

.about-section-2 {
  background-image: url("../../images/about-banner-2.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto;
}

.about-section-2 h2 {
  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 3rem 2rem 2rem 2rem;
  margin: 0;
}

.about-section-2 p {
  color: #212121;
  font-family: "DM Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.about-section-2-title {
  color: #212121;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.about-section-2-text {
  color: #212121;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 991px) {
  .about-section-2 h2 {
    font-size: 26px;
  }
  
  .about-section-2 p {
    font-size: 16px;
  }
  
  .about-section-2-title {
    font-size: 16px;
  }
  
  .about-section-2-text {
    font-size: 16px;
  }
}