.footer {
  background-image: url("../images/footer-banner-1.png");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: auto;

  border-top: solid 1px rgba(170, 168, 167, 0.5);

  color: #212121;
  font-family: "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer h2 {
  color: #212121;
  font-family: "DM Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
